import React from 'react'
import styles from './Credits.module.scss'
import HoodpayLogo from '../../images/HoodpayLogo'
import HoodpaySignatureLogo from '../../images/HoodpaySignatureLogo'

const Credits = () => {
	return (
		<div
			className={styles.credits}
			onClick={() => window.open('https://hoodpay.io', '_blank', 'noopener')}
		>
			<div className={styles.logo}>
				<HoodpayLogo />
				<HoodpaySignatureLogo />
			</div>
			<p>This checkout is secured and processed by our payments platform, Hoodpay.</p>
		</div>
	)
}

export default Credits

import React from 'react'

const RefreshIcon = () => {
	return (
		<svg width='19' height='20' viewBox='0 0 19 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M8.62069 13.4138L5.68966 16.2069M5.68966 16.2069L8.62069 19M5.68966 16.2069H10.9655C14.8505 16.2069 18 12.8722 18 8.75862V8.44828M13.3103 3.7931H8.03448C4.14944 3.7931 1 7.12781 1 11.2414V11.5517M13.3103 3.7931L10.3793 6.58621M13.3103 3.7931L10.3793 1'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default RefreshIcon

import { PaymentMethods } from '../types/PaymentMethod'
import cashAppLogo from '../images/CashAppLogo.svg'
import applePayLogo from '../images/ApplePayLogo.svg'
import googlePayLogo from '../images/GooglePayLogo.svg'
import wireLogo from '../images/WireLogo.svg'
import cryptoLogo from '../images/Crypto.svg'
import cardLogo from '../images/Card.svg'
import sepaLogo from '../images/sepaLogo.svg'
import openBankingLogo from '../images/openBankingLogo.svg'

export const getPaymentMethodIcon = (paymentMethod: PaymentMethods | undefined) => {
	switch (paymentMethod) {
		case PaymentMethods.CASHAPP:
			return cashAppLogo
		case PaymentMethods.CRYPTO:
			return cryptoLogo
		case PaymentMethods.BANK_CARD:
			return cardLogo
		case PaymentMethods.PAYPAL:
			return 'PayPal'
		case PaymentMethods.GOOGLE_PAY:
			return googlePayLogo
		case PaymentMethods.APPLE_PAY:
			return applePayLogo
		case PaymentMethods.WIRE:
			return wireLogo
		case PaymentMethods.VENMO:
			return 'Venmo'
		case PaymentMethods.SEPA:
			return sepaLogo
		case PaymentMethods.OPEN_BANKING:
			return openBankingLogo
	}
}

import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import AppContextProvider from './context/AppContext'
import NotFoundPage from './components/404/404Page'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<AppContextProvider>
				<Routes>
					<Route element={<App />} path={'/:id'} />
					<Route path='*' element={<NotFoundPage />} />
				</Routes>
			</AppContextProvider>
		</BrowserRouter>
	</React.StrictMode>
)

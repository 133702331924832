import { publicAPI } from './API'
import { PaymentMethods } from '../types/PaymentMethod'

export const PublicPaymentsHostedPageOnRampService = {
	getOptions: async (paymentId: string | undefined, customerEmail: string) => {
		return publicAPI.get(`/v1/public/payments/hosted-page/${paymentId}/on-ramp/get-options`, {
			params: {
				customerEmail,
			},
		})
	},
	changePaymentMethod: async (
		paymentId: string | undefined,
		data: {
			providerName: string
			paymentMethod: PaymentMethods | undefined
		}
	) => {
		return publicAPI.post(
			`/v1/public/payments/hosted-page/${paymentId}/on-ramp/change-payment-method`,
			data
		)
	},
}

import React from 'react'

const HoodpayLogo = () => {
	return (
		<svg width='11' height='18' viewBox='0 0 11 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M5.4325 3.30291L6.28735 14.8069L1.04724 14.4972L0.192383 2.99323L5.4325 3.30291Z'
				fill='#1F1F1F'
			/>
			<path
				d='M8.48032 6.17468L9.33518 17.6787L4.09507 17.369L3.24021 5.86501L8.48032 6.17468Z'
				fill='#1F1F1F'
			/>
			<path
				d='M10.1218 0.577499L10.9767 12.0815L5.73657 11.7718L4.88171 0.267822L10.1218 0.577499Z'
				fill='#1F1F1F'
			/>
		</svg>
	)
}

export default HoodpayLogo

import React from 'react'

const QuestionMarkSvg = () => {
	return (
		<svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M6.234 5.59766C6.42009 5.22207 6.70747 4.90599 7.06371 4.68511C7.41995 4.46423 7.83084 4.34734 8.25 4.34766C8.69647 4.34763 9.13285 4.48044 9.50362 4.72917C9.87438 4.97791 10.1628 5.33132 10.3321 5.74445C10.5014 6.15757 10.5439 6.61173 10.4544 7.04912C10.3648 7.48651 10.1471 7.88736 9.829 8.20066C9.329 8.69366 8.721 9.22566 8.427 9.85066M8.25 12.8477V12.8577M8.25 15.8477C7.29792 15.8477 6.35516 15.6601 5.47554 15.2958C4.59593 14.9314 3.7967 14.3974 3.12348 13.7242C2.45025 13.051 1.91622 12.2517 1.55187 11.3721C1.18753 10.4925 1 9.54974 1 8.59766C1 7.64557 1.18753 6.70281 1.55187 5.8232C1.91622 4.94359 2.45025 4.14436 3.12348 3.47113C3.7967 2.79791 4.59593 2.26388 5.47554 1.89953C6.35516 1.53518 7.29792 1.34766 8.25 1.34766C10.1728 1.34766 12.0169 2.11149 13.3765 3.47113C14.7362 4.83077 15.5 6.67484 15.5 8.59766C15.5 10.5205 14.7362 12.3645 13.3765 13.7242C12.0169 15.0838 10.1728 15.8477 8.25 15.8477Z'
				stroke='#305AD6'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default QuestionMarkSvg

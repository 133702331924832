import { publicAPI } from './API'
import { CryptoMethod } from '../types/CryptoMethod'
import { Cryptocurrency } from '../types/Cryptocurrencies'
import { PaymentMethods } from '../types/PaymentMethod'

export const PublicPaymentsHostedPageService = {
	get: async (paymentId: string | undefined) => {
		return publicAPI.get(`/v1/public/payments/hosted-page/${paymentId}`)
	},
	selectPaymentMethod: async (
		paymentId: string | undefined,
		cryptocurrency?: {
			crypto: Cryptocurrency
			xPub: boolean
		},
		onRamp_Crypto?: {
			providerName?: string
			paymentMethod?: PaymentMethods
		} | null
	) => {
		return publicAPI.post(`/v1/public/payments/hosted-page/${paymentId}/select-payment-method`, {
			[cryptocurrency?.xPub ? 'xPub_Crypto' : 'direct_Crypto']: cryptocurrency?.crypto,
			onRamp_Crypto,
		})
	},
	customerEmail: async (paymentId: string | undefined, email: string) => {
		return publicAPI.post(`/v1/public/payments/hosted-page/${paymentId}/customer_email`, {
			email,
		})
	},
	cancel: async (paymentId: string | undefined) => {
		return publicAPI.post(`/v1/public/payments/hosted-page/${paymentId}/cancel`)
	},
	completeAdmin: async (paymentId: string | undefined) => {
		return publicAPI.post(`/v1/public/payments/hosted-page/${paymentId}/complete-admin`)
	},
	manuallyLinkTransaction: async (
		paymentId: string | undefined,
		transactionId: string | undefined
	) => {
		return publicAPI.post(
			`/v1/public/payments/hosted-page/${paymentId}/manually-link-transaction`,
			{
				transactionId,
			}
		)
	},
}

import React from 'react'

const OpenLinkSvg = () => {
	return (
		<svg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M6.89655 1H3.62069C2.17332 1 1 2.17332 1 3.62069V17.3793C1 18.8267 2.17332 20 3.62069 20H17.3793C18.8267 20 20 18.8267 20 17.3793V14.1034M20 6.89655V1H14.1034M19.6724 1.32759L10.1724 10.8276'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default OpenLinkSvg

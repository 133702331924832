import { PaymentMethods } from '../types/PaymentMethod'

export const getPaymentMethodName = (paymentMethod: PaymentMethods | undefined) => {
	switch (paymentMethod) {
		case PaymentMethods.CASHAPP:
			return 'Cash App'
		case PaymentMethods.CRYPTO:
			return 'Crypto'
		case PaymentMethods.BANK_CARD:
			return 'Bank Card'
		case PaymentMethods.PAYPAL:
			return 'PayPal'
		case PaymentMethods.GOOGLE_PAY:
			return 'Google Pay'
		case PaymentMethods.APPLE_PAY:
			return 'Apple Pay'
		case PaymentMethods.WIRE:
			return 'Wire Transfer'
		case PaymentMethods.VENMO:
			return 'Venmo'
		case PaymentMethods.SEPA:
			return 'Single Euro Payments Area'
		case PaymentMethods.OPEN_BANKING:
			return 'Bank Transfer'
	}
}

import React from 'react'

const SendPaymentIcon = () => {
	return (
		<svg width='56' height='56' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M16.5987 18.1379V8.27586M16.5987 8.27586C16.5987 11.7419 25.0281 14.5517 35.4263 14.5517C45.8245 14.5517 54.2539 11.7419 54.2539 8.27586M16.5987 8.27586C16.5987 4.80979 25.0281 2 35.4263 2C45.8245 2 54.2539 4.80979 54.2539 8.27586M54.2539 8.27586V22.6207C54.2539 24.1714 52.567 25.5904 49.7711 26.6857M39.9091 33.3793V47.7241C39.9091 51.1902 31.4797 54 21.0815 54C10.6833 54 2.25391 51.1902 2.25391 47.7241V33.3793M39.9091 33.3793C39.9091 36.8454 31.4797 39.6552 21.0815 39.6552C10.6833 39.6552 2.25391 36.8454 2.25391 33.3793M39.9091 33.3793C39.9091 29.9132 31.4797 27.1034 21.0815 27.1034C10.6833 27.1034 2.25391 29.9132 2.25391 33.3793'
				stroke='#578643'
				strokeWidth='3'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default SendPaymentIcon

import React from 'react'

const UnderpaidPaymentIcon = () => {
	return (
		<svg width='56' height='56' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M16.6856 18.1379V8.27586M16.6856 8.27586C16.6856 11.7419 25.115 14.5517 35.5132 14.5517C45.9114 14.5517 54.3408 11.7419 54.3408 8.27586M16.6856 8.27586C16.6856 4.80979 25.115 2 35.5132 2C45.9114 2 54.3408 4.80979 54.3408 8.27586M54.3408 8.27586V22.6207C54.3408 24.1714 52.6539 25.5904 49.8581 26.6857M39.996 33.3793V47.7241C39.996 51.1902 31.5666 54 21.1684 54C10.7702 54 2.34082 51.1902 2.34082 47.7241V33.3793M39.996 33.3793C39.996 36.8454 31.5666 39.6552 21.1684 39.6552C10.7702 39.6552 2.34082 36.8454 2.34082 33.3793M39.996 33.3793C39.996 29.9132 31.5666 27.1034 21.1684 27.1034C10.7702 27.1034 2.34082 29.9132 2.34082 33.3793'
				stroke='#F7931A'
				strokeWidth='3'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default UnderpaidPaymentIcon

import React, { createContext, FC, ReactNode, useEffect, useState } from 'react'
import { AppContextType } from '../types/AppContextType'
import { ActionMessage } from '../types/ActionMessage'
import ActionMessageComponent from '../components/UI/ActionMessage/ActionMessage'

export const AppContext = createContext<AppContextType | null>(null)

const AppContextProvider: FC<{
	children: ReactNode
}> = ({ children }) => {
	const [actionMessage, setActionMessage] = useState<ActionMessage>({
		message: 'Unhandled error',
		title: 'Error',
		status: false,
		isActive: false,
	})

	return (
		<AppContext.Provider
			value={{
				setActionMessage,
				actionMessage,
			}}
		>
			<ActionMessageComponent />
			{children}
		</AppContext.Provider>
	)
}

export default AppContextProvider

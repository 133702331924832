import React, { FC } from 'react'
import styles from './PaymentMethod.module.scss'
import { PaymentMethods } from '../../types/PaymentMethod'
import TickSvg from '../../images/TickSVG'
import { getPaymentMethodIcon } from '../../utils/getPaymentMethodIcon'
import { getPaymentMethodName } from '../../utils/getPaymentMethodName'

interface PaymentMethodProps {
	paymentMethod: PaymentMethods
	setCurrentPaymentMethod: (value: { method: PaymentMethods; providers?: string[] }) => void
	currentPaymentMethod?: { method: PaymentMethods; providers?: string[] }
	providers?: string[]
}

const PaymentMethodComponent: FC<PaymentMethodProps> = ({
	setCurrentPaymentMethod,
	currentPaymentMethod,
	paymentMethod,
	providers,
}) => {
	return (
		<div
			className={`${styles.paymentMethod} ${
				currentPaymentMethod?.method === paymentMethod ? styles.active : ''
			}`}
			onClick={() =>
				setCurrentPaymentMethod({
					method: paymentMethod,
					providers: providers,
				})
			}
		>
			<div>
				<img src={getPaymentMethodIcon(paymentMethod)} alt='' />
				<p>{getPaymentMethodName(paymentMethod)}</p>
			</div>
			{currentPaymentMethod?.method === paymentMethod && (
				<div className={styles.tickSVG}>
					<TickSvg />
				</div>
			)}
		</div>
	)
}

export default PaymentMethodComponent

import React, { FC } from 'react'
import styles from './CheckoutError.module.scss'
import PaymentFailedIcon from '../../images/PaymentFailedIcon'

interface CheckoutErrorProps {
	paymentId?: string
}

const CheckoutError: FC<CheckoutErrorProps> = ({ paymentId }) => {
	return (
		<div className={styles.checkoutError}>
			<PaymentFailedIcon />
			<h1>Checkout error</h1>
			<p>We couldn’t find any checkout with ID {paymentId}.</p>
		</div>
	)
}

export default CheckoutError

import React, { useEffect, useState } from 'react'
import './App.scss'
import FinalStep from './components/PaymentSteps/FinalStep'
import PaymentMainDetail from './components/PaymentMainDetail/PaymentMainDetail'
import FirstStep from './components/PaymentSteps/FirstStep'
import CheckoutStep from './components/PaymentSteps/CheckoutStep'
import { useParams } from 'react-router-dom'
import { useAPI } from './hooks/useAPI'
import { PublicPaymentsHostedPageService } from './API/PublicPaymentsHostedPageService'
import { Payment } from './types/Payment'
import Spinner from './components/UI/Spinner/Spinner'
import { Statuses } from './types/Status'
import CheckoutError from './components/CheckoutError/CheckoutError'
import Footer from './components/Footer/Footer'
import IssuesStep from './components/PaymentSteps/IssuesStep'
import { OnRampOptions } from './types/OnRampOptions'
import { PublicPaymentsHostedPageOnRampService } from './API/PublicPaymentsHostedPageOnRampService'
import paymentMethod from './components/PaymentMethod/PaymentMethod'

function App() {
	const [step, setStep] = useState(0)
	const params = useParams()
	const paymentId = params.id
	const [isMounted, setIsMounted] = useState(false)
	const [isRedirectActive, setIsRedirectActive] = useState(false)
	const [isCheckoutError, setIsCheckoutError] = useState(false)
	const [isLoading, paymentData, getPayment] = useAPI<Payment>(
		() => PublicPaymentsHostedPageService.get(paymentId),
		true,
		undefined,
		{
			onError: () => {
				setIsCheckoutError(true)
			},
		}
	)
	const paymentEndAmount = paymentData
		? paymentData?.serviceFeeTransferRequestPercentages.includeInCustomerCoverProcessingFees
			? paymentData?.serviceFeeTransferRequestPercentages.overrideAmounts &&
			  paymentData.onRampCryptoCharge
				? (
						paymentData?.rawAmount +
						(paymentData.rawAmount / 100) *
							paymentData?.serviceFeeTransferRequestPercentages.overrideAmounts?.ONRAMP_CRYPTO['']
				  ).toFixed(2)
				: (
						paymentData?.rawAmount +
						(paymentData.rawAmount / 100) *
							paymentData?.serviceFeeTransferRequestPercentages.baseAmount
				  ).toFixed(2)
			: paymentData?.rawAmount.toFixed(2)
		: '0.00'

	const [isGetOnRampOptionsLoading, onRampOptions, getOnRampOptions] = useAPI<OnRampOptions>(
		email => PublicPaymentsHostedPageOnRampService.getOptions(paymentData?.id, email),
		false
	)

	useEffect(() => {
		let getPaymentsInterval
		const paymentStatus = paymentData?.status

		if (paymentData && !isMounted) {
			setIsMounted(true)
			getPaymentsInterval = setInterval(getPayment, 15000)
		}
		if (paymentStatus === Statuses.SUCCESS && paymentData?.redirectUrl && isRedirectActive) {
			window.location.href = paymentData.redirectUrl
		}
		if (paymentData?.directCryptoCharge || paymentData?.xPubCryptoCharge) {
			setStep(1)
		}
		if (paymentData?.onRampCryptoCharge) {
			setStep(2)
			if (paymentData.onRampCryptoPaymentMethod && !isMounted) {
				getOnRampOptions(paymentData.customer?.email)
			}
		}

		if (
			paymentStatus === Statuses.SUCCESS ||
			paymentStatus === Statuses.EXPIRED ||
			paymentStatus === Statuses.CANCELLED
		) {
			clearInterval(getPaymentsInterval)
		}

		if (
			paymentData &&
			paymentStatus !== Statuses.AWAITING_PAYMENT &&
			paymentStatus !== Statuses.CONFIRMING
		) {
			setStep(2)
		} else {
			setIsRedirectActive(true)
		}
	}, [paymentData])

	if (isLoading && !isMounted) {
		return <Spinner />
	}
	if (isCheckoutError) return <CheckoutError paymentId={paymentId} />

	return (
		<div className='App'>
			<PaymentMainDetail
				payment={paymentData}
				step={step}
				setStep={setStep}
				paymentEndAmount={paymentEndAmount}
			/>

			{step === 0 && (
				<FirstStep
					setStep={setStep}
					step={step}
					payment={paymentData}
					paymentEndAmount={paymentEndAmount}
					getPayment={getPayment}
					getOnRampOptions={getOnRampOptions}
					onRampOptions={onRampOptions}
					isGetOnRampOptionsLoading={isGetOnRampOptionsLoading}
				/>
			)}

			{step === 1 && (
				<CheckoutStep
					paymentId={paymentId}
					payment={paymentData}
					paymentEndAmount={paymentEndAmount}
					getPayment={getPayment}
				/>
			)}
			{step === 2 && (
				<FinalStep
					setStep={setStep}
					payment={paymentData}
					getPayment={getPayment}
					onRampOptions={onRampOptions}
				/>
			)}
			<div className={'footerWrapper'}>
				<Footer />
			</div>
		</div>
	)
}

export default App

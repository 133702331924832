import React, { FC, ReactNode } from 'react'
import styles from './Button.module.scss'
import Spinner from '../Spinner/Spinner'

interface ButtonProps {
	icon?: ReactNode
	children: ReactNode
	style?:
		| ButtonStyles.SECONDARY
		| ButtonStyles.TRANSPARENT
		| ButtonStyles.DANGER
		| ButtonStyles.PRIMARY
		| ButtonStyles.THIRD
	size?: 'small' | 'large'
	onClick?: () => void
	disabled?: boolean
	isLoading?: boolean
}

export enum ButtonStyles {
	PRIMARY = 'primary',
	SECONDARY = 'secondary',
	THIRD = 'third',
	TRANSPARENT = 'transparent',
	DANGER = 'danger',
}

const Button: FC<ButtonProps> = ({ style, icon, children, size, onClick, disabled, isLoading }) => {
	return (
		<button
			disabled={disabled}
			onClick={onClick}
			className={`${styles.button} ${style && styles[style]} ${size && styles[size]}`}
		>
			{children}
			{icon}
			{isLoading && <Spinner className={styles.spinner} />}
		</button>
	)
}

export default Button

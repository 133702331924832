import React from 'react'

const HoodpaySignatureLogo = () => {
	return (
		<svg
			width='130'
			height='15'
			viewBox='0 0 130 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M0.429688 14.0886H5.29312V8.77791H12.6535V14.0886H17.5169V0.74673H12.6535V5.47972H5.29312V0.74673H0.429688V14.0886Z'
				fill='#191919'
			/>
			<path
				d='M28.8202 14.5544C34.6899 14.5544 38.603 11.7034 38.603 7.41764C38.603 3.13186 34.6899 0.280884 28.8202 0.280884C22.9692 0.280884 19.0561 3.13186 19.0561 7.41764C19.0561 11.7034 22.9692 14.5544 28.8202 14.5544ZM28.8202 11.1258C25.8947 11.1258 23.9195 9.63507 23.9195 7.41764C23.9195 5.20022 25.8947 3.70951 28.8202 3.70951C31.7457 3.70951 33.7396 5.20022 33.7396 7.41764C33.7396 9.63507 31.7457 11.1258 28.8202 11.1258Z'
				fill='#191919'
			/>
			<path
				d='M49.5831 14.5544C55.4528 14.5544 59.3659 11.7034 59.3659 7.41764C59.3659 3.13186 55.4528 0.280884 49.5831 0.280884C43.7321 0.280884 39.819 3.13186 39.819 7.41764C39.819 11.7034 43.7321 14.5544 49.5831 14.5544ZM49.5831 11.1258C46.6576 11.1258 44.6824 9.63507 44.6824 7.41764C44.6824 5.20022 46.6576 3.70951 49.5831 3.70951C52.5086 3.70951 54.5024 5.20022 54.5024 7.41764C54.5024 9.63507 52.5086 11.1258 49.5831 11.1258Z'
				fill='#191919'
			/>
			<path
				d='M60.8987 14.0886H70.3833C75.5635 14.0886 78.4517 11.7034 78.4517 7.41764C78.4517 3.13186 75.5635 0.74673 70.3833 0.74673H60.8987V14.0886ZM65.6316 10.7904V4.02628H69.7125C72.2839 4.02628 73.7374 5.25612 73.7374 7.41764C73.7374 9.57917 72.2839 10.7904 69.7125 10.7904H65.6316Z'
				fill='#191919'
			/>
			<path
				d='M80.0056 14.0886H84.869V10.0077H90.7201C94.5214 10.0077 96.627 8.3866 96.6456 5.44246C96.6456 2.42377 94.54 0.74673 90.7573 0.74673H80.0056V14.0886ZM84.869 7.26857V3.4859H89.1362C90.8319 3.4859 91.7635 4.15672 91.7635 5.36792C91.7635 6.59775 90.8319 7.26857 89.1362 7.26857H84.869Z'
				fill='#191919'
			/>
			<path
				d='M95.0332 14.0886H100.064L101.089 11.7593H107.741L108.785 14.0886H113.835L107.332 0.74673H101.685L95.0332 14.0886ZM102.04 9.02015L104.425 3.74678H104.462L106.81 9.02015H102.04Z'
				fill='#191919'
			/>
			<path
				d='M117.858 14.0886H122.722V9.5419L129.113 0.74673H123.933L120.318 6.37415H120.299L116.498 0.74673H111.374L117.858 9.5419V14.0886Z'
				fill='#191919'
			/>
		</svg>
	)
}

export default HoodpaySignatureLogo

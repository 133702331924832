import React, { useEffect, useRef, useState } from 'react'
import styles from './ActionMessage.module.scss'
import SuccessStatusIcon from '../../../images/SuccessStatusIcon'
import CloseButton from '../CloseButton/CloseButton'
import { useAppContext } from '../../../hooks/useAppContext'
import ErrorIcon from '../../../images/ErrorIcon'

const ActionMessage = () => {
	const { actionMessage, setActionMessage } = useAppContext()
	const actionMessageRef = useRef<HTMLDivElement>(null)
	const [countdown, setCountdown] = useState<number | null>(null)

	const hideMessage = () => {
		setActionMessage({ ...actionMessage, isActive: false, status: false })
		actionMessageRef.current?.classList.remove(styles.visible)
		setTimeout(() => {
			actionMessageRef.current?.classList.remove(styles.active)
		}, 300)
	}

	useEffect(() => {
		if (actionMessage.isActive) {
			setCountdown(5)
			actionMessageRef.current?.classList.add(styles.active)
			setTimeout(() => {
				actionMessageRef.current?.classList.add(styles.visible)
			}, 10)
		}
	}, [actionMessage])

	useEffect(() => {
		if (countdown) {
			setTimeout(() => {
				setCountdown(countdown - 1)
			}, 1000)
		}
		if (countdown === 0) {
			hideMessage()
		}
	}, [countdown])

	return (
		<div
			className={`${styles.actionMessage} ${!actionMessage.status ? styles.error : ''}`}
			ref={actionMessageRef}
		>
			<div className={`${styles.actionMessageMain} `}>
				{actionMessage.status ? <SuccessStatusIcon /> : <ErrorIcon />}
				<h3>{actionMessage.title}</h3>
				<p>{actionMessage.message}</p>
			</div>
			<div className={styles.end}>
				<p className={styles.countdown}>{countdown}</p>
				<div className={styles.closeButton}>
					<CloseButton
						color={actionMessage.status ? 'green' : 'red'}
						onClick={() => hideMessage()}
					/>
				</div>
			</div>
		</div>
	)
}

export default ActionMessage

import React, { FC, useEffect, useState } from 'react'
import styles from './PaymentSteps.module.scss'
import Input from '../UI/FormComponents/Input'
import Button, { ButtonStyles } from '../UI/Button/Button'
import CryptocurrencyComponent from '../Cryptocurrency/Cryptocurrency'
import { CryptoMethod } from '../../types/CryptoMethod'
import { useAPI } from '../../hooks/useAPI'
import { PublicPaymentsHostedPageService } from '../../API/PublicPaymentsHostedPageService'
import { Payment } from '../../types/Payment'
import { PaymentMethodData } from '../../types/PaymentMethodData'
import { Cryptocurrency } from '../../types/Cryptocurrencies'
import { getCryptoShortName } from '../../utils/getCryptoShortName'
import NetworksStep from './NetworksStep'
import PaymentMethodComponent from '../PaymentMethod/PaymentMethod'
import { PaymentMethods } from '../../types/PaymentMethod'
import { OnRampOptions } from '../../types/OnRampOptions'
import Spinner from '../UI/Spinner/Spinner'

interface FirstStepProps {
	step: number
	setStep: (step: number) => void
	payment?: Payment
	paymentEndAmount: string
	getPayment: () => void
	isGetOnRampOptionsLoading: boolean
	getOnRampOptions: (email: string) => void
	onRampOptions?: OnRampOptions
}

const FirstStep: FC<FirstStepProps> = ({
	step,
	setStep,
	payment,
	paymentEndAmount,
	getPayment,
	getOnRampOptions,
	isGetOnRampOptionsLoading,
	onRampOptions,
}) => {
	const [email, setEmail] = useState('')
	const [isEmailConfirmed, setIsEmailConfirmed] = useState(false)
	const [agreementCheckbox, setAgreementCheckbox] = useState(false)
	const [cryptoMethods, setCryptoMethods] = useState<CryptoMethod>()
	const [currentPaymentMethod, setCurrentPaymentMethod] = useState<{
		method: PaymentMethods
		providers?: string[]
	}>()
	const [currentCryptocurrency, setCurrentCryptocurrency] = useState<{
		crypto: Cryptocurrency
		xPub: boolean
	}>()
	const [isMultipleNetworks, setIsMultipleNetworks] = useState(false)

	const uniquePaymentMethods = () => {
		const cryptos: {
			[cryptoMethod: string]: {
				cryptoToWork: Cryptocurrency
				networks: string[]
				xPub: boolean
			}
		} = {}

		const cryptoHandler = (crypto: Cryptocurrency, xPub: boolean) => {
			if (cryptos[getCryptoShortName(crypto)] && !xPub) {
				cryptos[getCryptoShortName(crypto)] = {
					...cryptos[getCryptoShortName(crypto)],
					networks: [...cryptos[getCryptoShortName(crypto)].networks, crypto],
					xPub: xPub,
				}
			} else {
				cryptos[getCryptoShortName(crypto)] = {
					cryptoToWork: crypto,
					networks: [crypto],
					xPub: xPub,
				}
			}
		}

		payment?.directCryptoPaymentMethod?.forEach(crypto => cryptoHandler(crypto, false))
		payment?.xPubCryptoPaymentMethod?.forEach(crypto => cryptoHandler(crypto, true))

		return cryptos
	}

	const onContinueHandler = () => {
		if (
			!isMultipleNetworks &&
			cryptoMethods &&
			!payment?.onRampCryptoPaymentMethod &&
			cryptoMethods[getCryptoShortName(currentCryptocurrency?.crypto)]?.networks.length > 1
		) {
			setIsMultipleNetworks(true)
		} else if (isEmailConfirmed) {
			selectPaymentMethod()
		} else {
			!payment?.customer?.email && postCustomerEmail()
		}
	}

	const isPayButtonEnable =
		email &&
		email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/) &&
		(isEmailConfirmed ? currentPaymentMethod : true)
	// (payment?.business.termsCheckbox ? agreementCheckbox : true)

	const [isCustomerEmailLoading, postCustomerEmailData, postCustomerEmail] = useAPI(
		() => PublicPaymentsHostedPageService.customerEmail(payment?.id, email),
		false,
		undefined,
		{
			onSuccess: () => {
				getOnRampOptions(email)
				setIsEmailConfirmed(true)
			},
		}
	)

	const [isSelectPaymentMethodLoading, selectPaymentMethodData, selectPaymentMethod] =
		useAPI<PaymentMethodData>(
			() => {
				const lastSucceedProvider = onRampOptions?.lastSucceededSelectedProvider?.providerName
				const lastSucceedPaymentMethod = onRampOptions?.lastSucceededSelectedProvider?.paymentMethod

				const isLastSucceedProviderAvailable =
					lastSucceedProvider && currentPaymentMethod?.providers?.includes(lastSucceedProvider)

				return PublicPaymentsHostedPageService.selectPaymentMethod(
					payment?.id,
					currentCryptocurrency,
					currentPaymentMethod && currentPaymentMethod?.method !== PaymentMethods.CRYPTO
						? {
								paymentMethod: currentPaymentMethod?.method,
								providerName:
									lastSucceedPaymentMethod === currentPaymentMethod.method &&
									isLastSucceedProviderAvailable
										? lastSucceedProvider
										: currentPaymentMethod.providers && currentPaymentMethod.providers[0],
						  }
						: null
				)
			},
			false,
			undefined
		)

	const handleChargeLink = () => {
		if (selectPaymentMethodData?.chargeLink) {
			const subAddress = window.open('', '_blank')

			setTimeout(() => {
				if (subAddress && selectPaymentMethodData.chargeLink) {
					subAddress.location.href = selectPaymentMethodData.chargeLink
				}
			}, 100)
		}
	}

	useEffect(() => {
		if (
			(payment?.customer?.email || postCustomerEmailData) &&
			selectPaymentMethodData &&
			payment?.id
		) {
			getPayment()
		}

		handleChargeLink()
	}, [postCustomerEmailData, selectPaymentMethodData])

	useEffect(() => {
		if (payment?.customer?.email) {
			setEmail(payment.customer.email)
			if (!isEmailConfirmed) {
				setIsEmailConfirmed(true)
			}
			if (!onRampOptions && isEmailConfirmed && payment.onRampCryptoPaymentMethod) {
				getOnRampOptions(payment.customer.email)
			}
		}
		if (payment) {
			setCryptoMethods(uniquePaymentMethods())
		}
	}, [payment, isEmailConfirmed])

	return (
		<div className={styles.paymentStep}>
			{/*<button className={styles.signInButton}>Sign in</button>*/}
			{isMultipleNetworks ? (
				<NetworksStep
					cryptoMethods={cryptoMethods}
					setCryptoMethods={setCryptoMethods}
					currentCryptocurrency={currentCryptocurrency}
					setCurrentCryptocurrency={setCurrentCryptocurrency}
				/>
			) : (
				<>
					<div className={styles.userInfo}>
						<div className={styles.fieldWrapper}>
							<p>Guest checkout</p>
							<Input
								value={email}
								setValue={setEmail}
								placeholder={'Your email'}
								disabled={!!payment?.customer?.email}
							/>
							{/*<span className={styles.fieldHelpText}>*/}
							{/*	Already have an account? <a href='#'>Sign in</a>*/}
							{/*</span>*/}
						</div>
					</div>

					{isEmailConfirmed && (
						<div className={styles.choosePaymentMethod}>
							<h3>Payment method</h3>

							{isGetOnRampOptionsLoading && isCustomerEmailLoading ? (
								<Spinner />
							) : (
								<div className={styles.paymentMethods}>
									{onRampOptions?.allPaymentMethods.map(paymentMethod => (
										<PaymentMethodComponent
											providers={paymentMethod.providers}
											paymentMethod={paymentMethod.method}
											setCurrentPaymentMethod={setCurrentPaymentMethod}
											currentPaymentMethod={currentPaymentMethod}
										/>
									))}
									<PaymentMethodComponent
										paymentMethod={PaymentMethods.CRYPTO}
										setCurrentPaymentMethod={setCurrentPaymentMethod}
										currentPaymentMethod={currentPaymentMethod}
									/>
								</div>
							)}
						</div>
					)}

					{/*{currentPaymentMethod?.method === PaymentMethods.BANK_CARD && (*/}
					{/*	<div className={styles.cardInformationForm}>*/}
					{/*		<div className={styles.fieldWrapper}>*/}
					{/*			<p>Card information</p>*/}
					{/*			<Input*/}
					{/*				value={email}*/}
					{/*				setValue={setEmail}*/}
					{/*				placeholder={'Name on card'}*/}
					{/*				disabled={!!payment?.customer?.email}*/}
					{/*			/>*/}
					{/*		</div>*/}
					{/*		<div className={styles.fieldWrapper}>*/}
					{/*			<div className={styles.brickInputs}>*/}
					{/*				<Input value={email} setValue={setEmail} placeholder={'1111 1111 1111 1111'} />*/}
					{/*				<Input value={email} setValue={setEmail} placeholder={'MM/YY'} />*/}
					{/*				<Input value={email} setValue={setEmail} placeholder={'CVC'} />*/}
					{/*			</div>*/}
					{/*		</div>*/}
					{/*		<div className={styles.fieldWrapper}>*/}
					{/*			<Select value={email} setValue={setEmail}>*/}
					{/*				<option value='United States'>United States</option>*/}
					{/*				<option value=''></option>*/}
					{/*			</Select>*/}
					{/*		</div>*/}
					{/*		<div className={styles.fieldWrapper}>*/}
					{/*			<div className={styles.brickInputs}>*/}
					{/*				<Input value={email} setValue={setEmail} placeholder={'Billing address'} />*/}
					{/*				<Input value={email} setValue={setEmail} placeholder={'City'} />*/}
					{/*				<Input value={email} setValue={setEmail} placeholder={'State'} />*/}
					{/*				<Input value={email} setValue={setEmail} placeholder={'Postal code (zip)'} />*/}
					{/*			</div>*/}
					{/*		</div>*/}
					{/*		<div className={styles.fieldWrapper}>*/}
					{/*			<Input value={email} setValue={setEmail} placeholder={'VAT ID (optional)'} />*/}
					{/*		</div>*/}
					{/*	</div>*/}
					{/*)}*/}

					{currentPaymentMethod?.method === PaymentMethods.CRYPTO && (
						<div className={styles.choosePaymentMethod}>
							<h3>Select cryptocurrency</h3>

							<div className={styles.paymentMethods}>
								{cryptoMethods &&
									Object.keys(cryptoMethods).map(key => (
										<CryptocurrencyComponent
											cryptocurrency={cryptoMethods[key].cryptoToWork}
											xPub={cryptoMethods[key].xPub}
											currentCryptocurrency={currentCryptocurrency}
											setCurrentCryptocurrency={setCurrentCryptocurrency}
										/>
									))}
							</div>
						</div>
					)}
				</>
			)}

			<Button
				disabled={!isPayButtonEnable || isCustomerEmailLoading}
				style={ButtonStyles.SECONDARY}
				onClick={onContinueHandler}
				isLoading={isCustomerEmailLoading || isSelectPaymentMethodLoading}
			>
				Continue →
			</Button>
			<div className={styles.agreementText}>
				By tapping “Continue”, you agree to {payment?.business.name}’s and Hoodpay’s terms and
				conditions.
			</div>
		</div>
	)
}

export default FirstStep

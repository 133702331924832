import React from 'react'
import styles from './404Page.module.scss'
const NotFoundPage = () => {
	window.location.href = 'https://hoodpay.io'
	return (
		<div className={styles.notFoundPage}>
			<h1>404</h1>
			<h2>Page not found</h2>
			<p>The Page you are looking doesn't exist</p>
		</div>
	)
}

export default NotFoundPage

import React from 'react'

const PaymentFailedIcon = () => {
	return (
		<svg width='57' height='57' viewBox='0 0 57 57' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M20.2759 20.2759L36.7241 36.7241M36.7241 20.2759L20.2759 36.7241M2 28.5C2 13.8645 13.8645 2 28.5 2C43.1357 2 55 13.8645 55 28.5C55 43.1357 43.1357 55 28.5 55C13.8645 55 2 43.1357 2 28.5Z'
				stroke='#D61719'
				strokeWidth='3'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default PaymentFailedIcon

import React, { FC, SVGProps } from 'react'

const CopyIcon: FC<SVGProps<any>> = props => {
	return (
		<svg
			width='14'
			height='16'
			viewBox='0 0 14 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9.96684 0.140381H1.49415C0.717483 0.140381 0.0820312 0.775833 0.0820312 1.5525V11.4373H1.49415V1.5525H9.96684V0.140381ZM12.085 2.96461H4.31838C3.54171 2.96461 2.90626 3.60006 2.90626 4.37673V14.2615C2.90626 15.0382 3.54171 15.6737 4.31838 15.6737H12.085C12.8617 15.6737 13.4971 15.0382 13.4971 14.2615V4.37673C13.4971 3.60006 12.8617 2.96461 12.085 2.96461ZM12.085 14.2615H4.31838V4.37673H12.085V14.2615Z'
				fill='#191919'
			/>
		</svg>
	)
}

export default CopyIcon

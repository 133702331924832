import React from 'react'

const ChatSvg = () => {
	return (
		<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M10 17.7602C14.7992 17.7602 19 15.1595 19 9.3801C19 3.60072 14.7992 1 10 1C5.20084 1 1 3.60072 1 9.3801C1 11.5744 1.60558 13.3105 2.61955 14.62C2.91617 15.0031 3.03401 15.5121 2.82868 15.9508C2.70361 16.2181 2.572 16.4733 2.43742 16.7151C1.87355 17.7282 2.65043 19.2042 3.78722 18.9765C5.04851 18.724 6.45899 18.3462 7.63567 17.7897C7.88134 17.6737 8.15457 17.6285 8.42407 17.6626C8.93862 17.7279 9.46559 17.7602 10 17.7602Z'
				stroke='#1F1F1F'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default ChatSvg

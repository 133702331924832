import React, { FC, useState } from 'react'
import styles from './PaymentMainDetail.module.scss'
import ArrowSVG from '../../images/ArrowSVG'
import { Payment } from '../../types/Payment'
import getSymbolFromCurrency from 'currency-symbol-map'
import Footer from '../Footer/Footer'
import PlusSVG from '../../images/PlusSVG'
import Input from '../UI/FormComponents/Input'
import Button, { ButtonStyles } from '../UI/Button/Button'

interface PaymentMainDetailProps {
	payment?: Payment
	setStep: (step: number) => void
	step: number
	paymentEndAmount: string
}

const PaymentMainDetail: FC<PaymentMainDetailProps> = ({ payment, paymentEndAmount }) => {
	const [promoCode, setPromoCode] = useState('')
	const [isPromoCodeFormActive, setIsPromoCodeFormActive] = useState(false)

	return (
		<div className={styles.paymentMainDetail}>
			{/*<button className={styles.backButton} onClick={() => step > 0 && setStep(step - 1)}>*/}
			{/*	<ArrowSVG />*/}
			{/*	<span>Back</span>*/}
			{/*</button>*/}

			<div className={styles.main}>
				<header className={styles.header}>
					<div className={styles.companyInfo}>
						{payment?.business.logoUrl && (
							<div className={`${styles.logo} ${payment?.business.logoUrl ? styles.noBg : ''}`}>
								<img
									src={`https://imagedelivery.net/${payment?.business.logoUrl}/logo`}
									alt='logo'
								/>
							</div>
						)}

						<div>
							<h2>{payment?.business.name}</h2>
							{payment?.name && <p>{payment?.name}</p>}
						</div>
					</div>
				</header>

				<div className={styles.detailWrapper}>
					<p>Subtotal</p>
					<span>
						{getSymbolFromCurrency(payment?.currency || 'USD')}
						{payment?.rawAmount.toFixed(2)}
					</span>
				</div>

				{/*<div className={styles.detailWrapper}>*/}
				{/*	<p>Tax (6.04%)</p>*/}
				{/*	<span>$0.8994</span>*/}
				{/*</div>*/}

				{payment?.serviceFeeTransferRequestPercentages.includeInCustomerCoverProcessingFees && (
					<div className={styles.detailWrapper}>
						<p>Service fee</p>
						<span>{payment?.serviceFeeTransferRequestPercentages.baseAmount.toFixed(2)}%</span>
					</div>
				)}

				{/*{isPromoCodeFormActive ? (*/}
				{/*	<div className={styles.promoCodeForm}>*/}
				{/*		<Input value={promoCode} setValue={setPromoCode} placeholder={'Add promo code'} />*/}
				{/*		<Button style={ButtonStyles.SECONDARY}>Apply</Button>*/}
				{/*	</div>*/}
				{/*) : (*/}
				{/*	<button*/}
				{/*		className={styles.addPromoCodeButton}*/}
				{/*		onClick={() => setIsPromoCodeFormActive(true)}*/}
				{/*	>*/}
				{/*		<PlusSVG />*/}
				{/*		Add promo code*/}
				{/*	</button>*/}
				{/*)}*/}

				<div className={`${styles.detailWrapper} ${styles.total}`}>
					<p>Due today</p>
					<span>
						{getSymbolFromCurrency(payment?.currency || 'USD')}
						{paymentEndAmount}
					</span>
				</div>
			</div>

			<div className={styles.footerWrapper}>
				<Footer />
			</div>
		</div>
	)
}

export default PaymentMainDetail

import React from 'react'
import styles from './Footer.module.scss'
import Credits from '../Credits/Credits'

const Footer = () => {
	return (
		<footer className={styles.footer}>
			<div className={styles.credits}>
				<Credits />
			</div>
			<ul className={styles.links}>
				<li>
					<a href='mailto:support@hoodpay.io'>support@hoodpay.io</a>
				</li>
				<li>
					<a href='mailto:support@hoodpay.io'>Terms</a>
				</li>
				<li>
					<a href='mailto:support@hoodpay.io'>Privacy</a>
				</li>
			</ul>
		</footer>
	)
}

export default Footer

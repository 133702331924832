import { Cryptocurrencies, Cryptocurrency } from '../types/Cryptocurrencies'
import { CryptoShortNames } from '../types/CryptoShortNames'

export const getCryptoShortName = (crypto: Cryptocurrency | undefined) => {
	switch (crypto) {
		case Cryptocurrencies.BITCOIN:
			return CryptoShortNames.BTC
		case Cryptocurrencies.BITCOIN_CASH:
			return CryptoShortNames.BCH
		case Cryptocurrencies.LITECOIN:
			return CryptoShortNames.LTC
		case Cryptocurrencies.ETH_BUSD:
			return CryptoShortNames.BUSD
		case Cryptocurrencies.ETH_DAI:
			return CryptoShortNames.DAI
		case Cryptocurrencies.ETH_CRONOS:
			return CryptoShortNames.CRO
		case Cryptocurrencies.MONERO:
			return CryptoShortNames.XMR
		case Cryptocurrencies.ETHEREUM:
			return CryptoShortNames.ETH
		case Cryptocurrencies.ETH_TETHER:
			return CryptoShortNames.USDT
		case Cryptocurrencies.ETH_BNB:
			return CryptoShortNames.BNB
		case Cryptocurrencies.ETH_USD_COIN:
			return CryptoShortNames.USDC
		case Cryptocurrencies.ETH_MATIC:
			return CryptoShortNames.MATIC
		case Cryptocurrencies.ETH_UNISWAP:
			return CryptoShortNames.UNI
		case Cryptocurrencies.ETH_APE_COIN:
			return CryptoShortNames.APE
		case Cryptocurrencies.ETH_SHIBA_INU:
			return CryptoShortNames.SHIBA
		case Cryptocurrencies.TRON:
			return CryptoShortNames.TRX
		case Cryptocurrencies.TRX_TETHER:
			return CryptoShortNames.USDT
		case Cryptocurrencies.TRX_USD_C:
			return CryptoShortNames.USDC
		default:
			return CryptoShortNames.BTC
	}
}

import React, { FC } from 'react'
import styles from './Cryptocurrency.module.scss'
import bitcoinIcon from '../../images/BitcoinSvg.svg'
import bitcoinCashIcon from '../../images/BitcoinCash.svg'
import litecoinIcon from '../../images/LitecoinSvg.svg'
import ethereumIcon from '../../images/Ethereum.svg'
import { Cryptocurrencies, Cryptocurrency } from '../../types/Cryptocurrencies'
import { getCryptoIcon } from '../../utils/getCryptoIcon'
import { getCryptoShortName } from '../../utils/getCryptoShortName'
import { CryptoShortName } from '../../types/CryptoShortNames'
import TickSvg from '../../images/TickSVG'

interface PaymentMethodProps {
	cryptocurrency: Cryptocurrency
	setCurrentCryptocurrency: (value: { crypto: Cryptocurrency; xPub: boolean }) => void
	currentCryptocurrency?: { crypto: Cryptocurrency; xPub: boolean }
	xPub: boolean
}

const CryptocurrencyComponent: FC<PaymentMethodProps> = ({
	setCurrentCryptocurrency,
	currentCryptocurrency,
	cryptocurrency,
	xPub,
}) => {
	return (
		<div
			className={`${styles.paymentMethod} ${
				currentCryptocurrency?.crypto === cryptocurrency ? styles.active : ''
			}`}
			onClick={() => setCurrentCryptocurrency({ crypto: cryptocurrency, xPub })}
		>
			<img src={getCryptoIcon(cryptocurrency)} alt={getCryptoShortName(cryptocurrency)} />
			<p>{getCryptoShortName(cryptocurrency)}</p>
			{currentCryptocurrency?.crypto === cryptocurrency && (
				<div className={styles.tickSVG}>
					<TickSvg />
				</div>
			)}
		</div>
	)
}

export default CryptocurrencyComponent

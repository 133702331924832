export enum CryptoContractIds {
	ETH_BNB = '0xB8c77482e45F1F44dE1745F52C74426C631bDD52',
	ETH_USD_COIN = '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
	ETH_TETHER = '0xdAC17F958D2ee523a2206206994597C13D831ec7',
	ETH_BUSD = '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
	ETH_MATIC = '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0',
	ETH_SHIBA_INU = '0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE',
	ETH_APE_COIN = '0x4d224452801ACEd8B2F0aebE155379bb5D594381',
	ETH_CRONOS = '0xA0b73E1Ff0B80914AB6fe0444E65848C4C34450b',
	ETH_DAI = '0x6B175474E89094C44Da98b954EedeAC495271d0F',
	ETH_UNISWAP = '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
}

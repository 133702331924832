import { Cryptocurrencies, Cryptocurrency } from '../types/Cryptocurrencies'

export const getCryptoNetwork = (crypto: Cryptocurrency | undefined) => {
	switch (crypto) {
		case Cryptocurrencies.BITCOIN:
			return 'Bitcoin'
		case Cryptocurrencies.BITCOIN_CASH:
			return 'Bitcoin cash'
		case Cryptocurrencies.LITECOIN:
			return 'Litecoin'
		case Cryptocurrencies.ETH_BUSD:
			return 'Ethereum'
		case Cryptocurrencies.ETH_DAI:
			return 'Ethereum'
		case Cryptocurrencies.ETH_CRONOS:
			return 'Ethereum'
		case Cryptocurrencies.MONERO:
			return 'Monero'
		case Cryptocurrencies.ETHEREUM:
			return 'Ethereum'
		case Cryptocurrencies.ETH_TETHER:
			return 'Ethereum'
		case Cryptocurrencies.ETH_BNB:
			return 'Ethereum'
		case Cryptocurrencies.ETH_USD_COIN:
			return 'Ethereum'
		case Cryptocurrencies.ETH_MATIC:
			return 'Ethereum'
		case Cryptocurrencies.ETH_UNISWAP:
			return 'Ethereum'
		case Cryptocurrencies.ETH_APE_COIN:
			return 'Ethereum'
		case Cryptocurrencies.ETH_SHIBA_INU:
			return 'Ethereum'
		case Cryptocurrencies.TRON:
			return 'Tron'
		case Cryptocurrencies.TRX_USD_C:
			return 'Tron'
		case Cryptocurrencies.TRX_TETHER:
			return 'Tron'
	}
}

import { Cryptocurrency } from '../types/Cryptocurrencies'
import tronIcon from '../images/tronIcon.svg'
import ethIcon from '../images/Ethereum.svg'
import ltcIcon from '../images/LitecoinSvg.svg'
import bitcoinIcon from '../images/BitcoinSvg.svg'

export const getNetworkIcon = (crypto: Cryptocurrency | undefined) => {
	if (crypto?.startsWith('TRX_') || crypto === 'TRON') {
		return tronIcon
	}
	if (crypto?.startsWith('ETH_') || crypto === 'ETHEREUM') {
		return ethIcon
	}
	if (crypto === 'BITCOIN') {
		return bitcoinIcon
	}
	if (crypto === 'LITECOIN') {
		return ltcIcon
	}
}

import React from 'react';

const SuccessStatusIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9258 6.23486C11.9258 7.71142 11.3392 9.12751 10.2951 10.1716C9.25105 11.2157 7.83496 11.8022 6.3584 11.8022C4.88184 11.8022 3.46575 11.2157 2.42166 10.1716C1.37758 9.12751 0.791016 7.71142 0.791016 6.23486C0.791016 4.7583 1.37758 3.34222 2.42166 2.29813C3.46575 1.25404 4.88184 0.66748 6.3584 0.66748C7.83496 0.66748 9.25105 1.25404 10.2951 2.29813C11.3392 3.34222 11.9258 4.7583 11.9258 6.23486ZM9.16297 4.12622C9.11326 4.07668 9.05407 4.03768 8.98894 4.01154C8.92381 3.9854 8.85408 3.97267 8.78391 3.9741C8.71375 3.97553 8.6446 3.99109 8.58059 4.01986C8.51657 4.04863 8.45902 4.09001 8.41137 4.14153L5.99443 7.22099L4.53786 5.76372C4.43892 5.67153 4.30806 5.62134 4.17284 5.62372C4.03762 5.62611 3.9086 5.68088 3.81298 5.77651C3.71735 5.87214 3.66257 6.00116 3.66018 6.13637C3.6578 6.27159 3.70799 6.40246 3.80019 6.5014L5.6416 8.34351C5.6912 8.39303 5.75028 8.43204 5.81529 8.45824C5.8803 8.48443 5.94992 8.49726 6.02 8.49596C6.09008 8.49466 6.15918 8.47926 6.22318 8.45068C6.28718 8.42209 6.34476 8.38091 6.3925 8.32959L9.17062 4.85694C9.26533 4.75846 9.31765 4.62678 9.31635 4.49016C9.31505 4.35353 9.26023 4.22287 9.16366 4.12622H9.16297Z"
        fill="#4F841A"/>
    </svg>
  );
};

export default SuccessStatusIcon;
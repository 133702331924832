import React, { FC } from 'react'
import styles from './PaymentSteps.module.scss'
import { CryptoMethod } from '../../types/CryptoMethod'
import { Cryptocurrency } from '../../types/Cryptocurrencies'
import bitcoinImg from '../../images/BitcoinSvg.svg'
import ethereumImg from '../../images/Ethereum.svg'
import tronImg from '../../images/Tron.svg'
import { getCryptoShortName } from '../../utils/getCryptoShortName'

interface NetworksStepProps {
	cryptoMethods?: CryptoMethod
	setCryptoMethods: (cryptoMethod: CryptoMethod) => void
	setCurrentCryptocurrency: (value: { crypto: Cryptocurrency; xPub: boolean }) => void
	currentCryptocurrency?: { crypto: Cryptocurrency; xPub: boolean }
}
const NetworksStep: FC<NetworksStepProps> = ({
	cryptoMethods,
	setCryptoMethods,
	setCurrentCryptocurrency,
	currentCryptocurrency,
}) => {
	const cryptoMethod =
		cryptoMethods && cryptoMethods[getCryptoShortName(currentCryptocurrency?.crypto)]

	const networks: {
		[netName: string]: {
			title: string
			img: string
			description: string
		}
	} = {
		BITCOIN: {
			title: 'Bitcoin (BTC Network)',
			img: bitcoinImg,
			description:
				'Select if you’re sending from Exodus, Coinbase, TrustWallet, or other crypto wallets.',
		},
		ETH: {
			title: 'Ethereum (ETH Network)',
			img: ethereumImg,
			description:
				'Select if you’re sending the payment from an Ethereum wallet or Ethereum network.',
		},
		TRX: {
			title: 'Tron (TRX Network)',
			img: tronImg,
			description: 'Select if you’re sending the payment from a Tron wallet or Tron network.',
		},
	}

	return (
		<div>
			<h3 className={styles.h3}>Choose a network</h3>
			{cryptoMethod &&
				cryptoMethod.networks.map(network => {
					const [networkName] = network.split('_')
					return (
						<div
							className={`${styles.networkWrapper} ${
								currentCryptocurrency?.crypto === network ? styles.active : ''
							}`}
							onClick={() =>
								setCurrentCryptocurrency({
									crypto: network as Cryptocurrency,
									xPub: cryptoMethod?.xPub,
								})
							}
						>
							<div className={styles.head}>
								<img src={networks[networkName].img} alt={networks[networkName].title} />
								<h4>{networks[networkName].title}</h4>
							</div>
							<p>{networks[networkName].description}</p>
						</div>
					)
				})}
		</div>
	)
}

export default NetworksStep

import React, { FC, useCallback, useEffect, useState } from 'react'
import styles from './PaymentSteps.module.scss'
import CopyIcon from '../../images/CopyIcon'
import { useAPI } from '../../hooks/useAPI'
import { PublicPaymentsHostedPageService } from '../../API/PublicPaymentsHostedPageService'
import { Payment } from '../../types/Payment'
import { useAppContext } from '../../hooks/useAppContext'
import { getCryptoShortName } from '../../utils/getCryptoShortName'
import { getCryptoNetwork } from '../../utils/getCryptoNetwork'
import QRCode from 'react-qr-code'
import { Cryptocurrencies, Cryptocurrency } from '../../types/Cryptocurrencies'
import { CryptoContractIds } from '../../types/CryptoContractIds'
import Modal from '../Modals/Modal'
import PaymentIssuesModal from '../Modals/PaymentIssuesModal'
import { closeModal } from '../../utils/closeModal'
import { getNetworkIcon } from '../../utils/getNetworkIcon'
import QuestionMarkSVG from '../../images/QuestionMarkSVG'
import { Statuses } from '../../types/Status'

interface CheckoutStepProps {
	paymentId: string | undefined
	payment?: Payment
	paymentEndAmount: string
	getPayment: () => void
}

const CheckoutStep: FC<CheckoutStepProps> = ({ paymentId, payment, getPayment }) => {
	const [isCancelLoading, , cancelPayment] = useAPI(
		() => PublicPaymentsHostedPageService.cancel(paymentId),
		false,
		'Payment successfully canceled',
		{
			onSuccess: getPayment,
		}
	)
	const cryptoCharge = payment?.directCryptoCharge || payment?.xPubCryptoCharge
	const [cancelTime, setCancelTime] = useState<number | null>(null)
	const { setActionMessage } = useAppContext()
	const [isPaymentIssuesModalActive, setIsPaymentIssuesModalActive] = useState(false)
	const [isPaymentIssuesModalVisible, setIsPaymentIssuesModalVisible] = useState(false)

	const getCancelTime = () => {
		const deadline = new Date(payment?.expiresAt as Date)
		const currentDate = new Date()

		return (deadline.valueOf() - currentDate.valueOf()) / 1000
	}

	const generateQRValue = useCallback((crypto: Cryptocurrency) => {
		const time = payment?.timeline[0].time && new Date(payment?.timeline[0].time).getTime() / 1000

		if (crypto.includes('ETH_')) {
			// @ts-ignore
			return `ethereum:${CryptoContractIds[crypto]}@1/transfer?address=${
				cryptoCharge?.address
			}&uint256=${(cryptoCharge?.amount || 0) * 1000000}`
		} else if (crypto.includes('TRX_')) {
			return `${cryptoCharge?.address}`
		} else {
			switch (crypto) {
				case Cryptocurrencies.BITCOIN:
					return `bitcoin:${cryptoCharge?.address}?amount=${
						cryptoCharge?.amount
					}&time=${time}&exp=${cancelTime?.toFixed(0)}`
				case Cryptocurrencies.LITECOIN:
					return `litecoin:${cryptoCharge?.address}?amount=${
						cryptoCharge?.amount
					}&time=${time}&exp=${cancelTime?.toFixed(0)}`
				case Cryptocurrencies.ETHEREUM:
					return `ethereum:${cryptoCharge?.address}@1?value=${
						(cryptoCharge?.amount || 0) * 1000000000000000000
					}`
				case Cryptocurrencies.TRON:
					return `${cryptoCharge?.address}`
				default:
					return ''
			}
		}
	}, [])
	const normalizeTime = (time: number) => {
		if (time < 10) {
			return `0${time}`
		}
		return time
	}

	const copyToClipboard = (value: string) => {
		window.navigator.clipboard.writeText(value)
		setActionMessage({
			title: 'Success',
			status: true,
			message: 'Successfully copied',
			isActive: true,
		})
	}

	useEffect(() => {
		setCancelTime(getCancelTime())
	}, [])

	useEffect(() => {
		if (cancelTime && !(cancelTime <= 0)) {
			setTimeout(() => {
				setCancelTime(cancelTime - 1)
			}, 1000)
		}
	}, [cancelTime])

	console.log(cancelTime)

	return (
		<>
			{isPaymentIssuesModalActive && (
				<Modal
					closeModal={() =>
						closeModal(setIsPaymentIssuesModalActive, setIsPaymentIssuesModalVisible)
					}
					visible={isPaymentIssuesModalVisible}
					setVisible={setIsPaymentIssuesModalVisible}
				>
					<PaymentIssuesModal
						close={() => closeModal(setIsPaymentIssuesModalActive, setIsPaymentIssuesModalVisible)}
						supportEmail={payment?.business.supportEmail}
						paymentId={paymentId}
						getPayment={getPayment}
					/>
				</Modal>
			)}
			<div className={styles.paymentStep}>
				{/*<div className={styles.buttonWrapper}>*/}
				{/*	<Button isLoading={isCancelLoading} style={ButtonStyles.DANGER} onClick={cancelPayment}>*/}
				{/*		Cancel payment*/}
				{/*	</Button>*/}
				{/*</div>*/}
				<div className={styles.stepInner}>
					<header className={styles.header}>
						<div>
							<h2>Send payment</h2>
							<p>
								Open your crypto wallet and scan the QR code, or copy the{' '}
								{getCryptoShortName(cryptoCharge?.coinName)} address below to make a payment.
							</p>
						</div>
						<p className={styles.timer}>
							{cancelTime && normalizeTime(Math.floor((cancelTime / 60 / 60) % 24))}:
							{cancelTime && normalizeTime(Math.floor(cancelTime / 60) % 60)}:
							{cancelTime && cancelTime > 0 && normalizeTime(Math.floor(cancelTime) % 60)}
						</p>
					</header>

					<div className={styles.checkoutDetails}>
						<div className={styles.qr}>
							<QRCode
								value={cryptoCharge?.coinName ? generateQRValue(cryptoCharge?.coinName) : ''}
								size={232}
							/>
						</div>
						{/*<Warning*/}
						{/*	textNode={*/}
						{/*		<p>*/}
						{/*			If you do not send the <u>exact amount</u> to the address below, your payment*/}
						{/*			won’t be processed and your <u>funds will be lost.</u>*/}
						{/*		</p>*/}
						{/*	}*/}
						{/*	titleNode={*/}
						{/*		<h2>*/}
						{/*			Send exactly{' '}*/}
						{/*			<span>*/}
						{/*				{payment?.directCryptoCharge?.amount}{' '}*/}
						{/*				{getCryptoShortName(payment?.directCryptoCharge?.coinName)}*/}
						{/*			</span>*/}
						{/*		</h2>*/}
						{/*	}*/}
						{/*/>*/}
						<div className={styles.cryptoDetails}>
							<div
								className={styles.cryptoDetailsItem}
								onClick={() => copyToClipboard(cryptoCharge?.address as string)}
							>
								<div>
									<span>{getCryptoShortName(cryptoCharge?.coinName)} address</span>
									<p>{cryptoCharge?.address}</p>
								</div>
								<CopyIcon />
							</div>
							<div
								className={styles.cryptoDetailsItem}
								onClick={() => copyToClipboard(`${cryptoCharge?.amount}`)}
							>
								<div>
									<span>Total amount</span>
									<p>{cryptoCharge?.amount}</p>
								</div>
								<CopyIcon />
							</div>
							<div className={styles.cryptoDetailsItem}>
								<div>
									<span>Network</span>
									<div className={styles.cryptoWrapper}>
										<img src={getNetworkIcon(cryptoCharge?.coinName)} alt='coin' />
										<p>{getCryptoNetwork(cryptoCharge?.coinName)}</p>
									</div>
								</div>
							</div>
						</div>
						<p className={styles.help} onClick={() => setIsPaymentIssuesModalActive(true)}>
							Issues with your payment? Click here for help.
							<QuestionMarkSVG />
						</p>
					</div>
				</div>
			</div>
		</>
	)
}

export default CheckoutStep

import React from 'react'

const AwaitingPaymentIcon = () => {
	return (
		<svg width='73' height='69' viewBox='0 0 73 69' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M36.5706 0.260251C46.1639 0.260252 55.1783 3.99791 61.9647 10.777C68.7438 17.5634 72.4814 26.5851 72.4814 36.1711C72.4814 42.9648 70.576 49.5754 66.9702 55.2991C63.4598 60.8616 58.5055 65.3541 52.6352 68.3003L49.6158 62.2761C54.3868 59.8869 58.4176 56.2299 61.2685 51.708C64.1926 47.0616 65.7463 41.6896 65.7463 36.1711C65.7463 20.0845 52.6572 6.99536 36.5706 6.99536C20.484 6.99536 7.3949 20.0845 7.3949 36.1711C7.3949 41.6896 8.94126 47.0616 11.8728 51.708C14.7236 56.2298 18.7544 59.8869 23.5255 62.2761L20.506 68.293C14.6357 65.3541 9.68147 60.8616 6.171 55.2991C2.56526 49.5827 0.659785 42.9648 0.659785 36.1711C0.659786 26.5778 4.39744 17.5634 11.1765 10.777C17.9629 3.9979 26.9773 0.26025 36.5706 0.260251Z'
				fill='#A7E23D'
			/>
		</svg>
	)
}

export default AwaitingPaymentIcon

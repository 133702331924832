import React, { FC, useEffect } from 'react'
import styles from './PaymentSteps.module.scss'
import QuestionMarkSVG2 from '../../images/QuestionMarkSVG2'
import Button from '../UI/Button/Button'
import RefreshIcon from '../../images/RefreshIcon'
import { format } from 'date-fns'
import { Payment } from '../../types/Payment'
import { getPaymentMethodIcon } from '../../utils/getPaymentMethodIcon'
import { getPaymentMethodName } from '../../utils/getPaymentMethodName'
import OpenLinkSVG from '../../images/OpenLinkSVG'
import { useAPI } from '../../hooks/useAPI'
import { PublicPaymentsHostedPageOnRampService } from '../../API/PublicPaymentsHostedPageOnRampService'
import { PaymentMethodData } from '../../types/PaymentMethodData'
import { ucFirst } from '../../utils/ucFirst'

interface IssuesStepProps {
	payment?: Payment
	handleOnRampOptions: () => string | undefined
	getPayment: () => void
}

const IssuesStep: FC<IssuesStepProps> = ({ payment, handleOnRampOptions, getPayment }) => {
	const onRampCryptoCharge = payment?.onRampCryptoCharge

	const [isChangePaymentMethodLoading, paymentMethodData, changePaymentMethod] =
		useAPI<PaymentMethodData>(
			(providerName: string) =>
				PublicPaymentsHostedPageOnRampService.changePaymentMethod(payment?.id, {
					providerName,
					paymentMethod: payment?.onRampCryptoCharge?.paymentMethod,
				}),
			false,
			'Payment method successfully changed'
		)

	useEffect(() => {
		if (paymentMethodData && paymentMethodData.chargeLink) {
			getPayment()
			const subAddress = window.open('', '_blank')

			setTimeout(() => {
				if (subAddress && paymentMethodData.chargeLink) {
					subAddress.location.href = paymentMethodData.chargeLink
				}
			}, 100)
		}
	}, [paymentMethodData])

	return (
		<div className={styles.paymentStep}>
			<div className={styles.stepInner}>
				<div className={styles.statusIcon}>
					<QuestionMarkSVG2 />
				</div>
				<header className={`${styles.header} ${styles.mbMedium}`}>
					<div>
						<h2>Experiencing issues?</h2>
						<p>
							If you weren’t able to successfully complete this payment with the previous payment
							provider, {ucFirst(payment?.onRampCryptoCharge?.currentProvider || '')} please tap on
							the button below. We’ll try again with another provider.
						</p>
					</div>
				</header>
				<div className={styles.buttons}>
					<button
						className={styles.bigButton}
						onClick={() =>
							window.open(payment?.onRampCryptoCharge?.purchaseUrl, '_blank', 'noopener')
						}
					>
						<OpenLinkSVG />
						Pay
					</button>
					<button
						className={styles.bigButton}
						onClick={() => changePaymentMethod(handleOnRampOptions())}
						disabled={isChangePaymentMethodLoading}
					>
						<RefreshIcon />
						Try another processor
					</button>
				</div>

				<div className={styles.transactionInfo}>
					<div className={styles.transactionInfoItem}>
						<p>Status</p>
						<span>{payment?.status}</span>
					</div>
					<div className={styles.transactionInfoItem}>
						<p>Transaction ID</p>
						<p>{payment?.id}</p>
					</div>
					<div className={styles.transactionInfoItem}>
						<p>Date & time</p>
						<p>
							{payment?.timeline[0].time &&
								format(new Date(payment?.timeline[0].time), 'MM/dd/yyyy, HH:mm aa')}
						</p>
					</div>
					<div className={styles.transactionInfoItem}>
						<p>Gateway</p>
						{
							<div className={styles.gateway}>
								<img src={getPaymentMethodIcon(onRampCryptoCharge?.paymentMethod)} alt='' />
								{getPaymentMethodName(onRampCryptoCharge?.paymentMethod)}
							</div>
						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default IssuesStep

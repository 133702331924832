import React from 'react'

const TickSvg = () => {
	return (
		<svg width='17' height='13' viewBox='0 0 17 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M1.54688 7.66649L5.245 11.7755L15.5176 1.09204'
				stroke='#305AD6'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default TickSvg

import React, { FC, useState } from 'react'
import styles from './PaymentSteps.module.scss'
import Button, { ButtonStyles } from '../UI/Button/Button'
import AwaitingPaymentIcon from '../../images/AwaitingPaymentIcon'
import { Payment } from '../../types/Payment'
import { Statuses } from '../../types/Status'
import PaymentFailedIcon from '../../images/PaymentFailedIcon'
import PaymentCompletedIcon from '../../images/PaymentCompletedIcon'
import { format } from 'date-fns'
import { getCryptoIcon } from '../../utils/getCryptoIcon'
import { getCryptoShortName } from '../../utils/getCryptoShortName'
import { Cryptocurrencies } from '../../types/Cryptocurrencies'
import UnderpaidPaymentIcon from '../../images/UnderpaidPaymentIcon'
import ChatSVG from '../../images/ChatSVG'
import OpenLinkSVG from '../../images/OpenLinkSVG'
import QuestionMarkSVG from '../../images/QuestionMarkSVG'
import Modal from '../Modals/Modal'
import { closeModal } from '../../utils/closeModal'
import PaymentIssuesModal from '../Modals/PaymentIssuesModal'
import { getPaymentMethodName } from '../../utils/getPaymentMethodName'
import { getPaymentMethodIcon } from '../../utils/getPaymentMethodIcon'
import SendPaymentIcon from '../../images/SendPaymentIcon'
import { OnRampOptions } from '../../types/OnRampOptions'
import IssuesStep from './IssuesStep'
import { ucFirst } from '../../utils/ucFirst'

interface FinalStepProps {
	payment?: Payment
	setStep: (step: number) => void
	getPayment: () => void
	onRampOptions?: OnRampOptions
}

const FinalStep: FC<FinalStepProps> = ({ payment, getPayment, onRampOptions }) => {
	const cryptoCharge =
		payment?.directCryptoCharge || payment?.xPubCryptoCharge || payment?.onRampCryptoCharge
	const onRampCryptoCharge = payment?.onRampCryptoCharge
	const [isPaymentIssuesModalActive, setIsPaymentIssuesModalActive] = useState(false)
	const [isPaymentIssuesModalVisible, setIsPaymentIssuesModalVisible] = useState(false)

	const openLinkHandler = () => {
		let link: string = ``
		const transactionId = cryptoCharge?.transactionId

		if (cryptoCharge?.coinName.includes('ETH')) {
			link = `https://etherscan.io/tx/${transactionId}`
		} else if (cryptoCharge?.coinName === Cryptocurrencies.BITCOIN) {
			link = `https://mempool.space/tx/${transactionId}`
		} else if (cryptoCharge?.coinName === Cryptocurrencies.LITECOIN) {
			link = `https://live.blockcypher.com/ltc/tx/${transactionId}`
		}
		window.open(link, '_blank', 'noopener')
	}

	const statusData = {
		[Statuses.SUCCESS]: {
			icon: <PaymentCompletedIcon />,
			description: 'Your payment is completed. You may now close this page.',
			title: 'Thank you',
		},
		[Statuses.NEW]: {
			icon: (
				<div className={styles.awaitingPaymentIcon}>
					<AwaitingPaymentIcon />
				</div>
			),
			description:
				'You can navigate away from this page and check back later for a final confirmation.',
			title: 'New',
		},
		[Statuses.CONFIRMING]: {
			icon: (
				<div className={styles.awaitingPaymentIcon}>
					<AwaitingPaymentIcon />
				</div>
			),
			description:
				'You can navigate away from this page and check back later for a final confirmation.',
			title: `Awaiting confirmations ${cryptoCharge?.currentTransactionConfirmations}/${cryptoCharge?.requiredConfirmations} `,
		},
		[Statuses.PENDING]: {
			icon: (
				<div className={styles.awaitingPaymentIcon}>
					<AwaitingPaymentIcon />
				</div>
			),
			description:
				'You can navigate away from this page and check back later for a final confirmation.',
			title: `Awaiting confirmations ${cryptoCharge?.currentTransactionConfirmations}/${cryptoCharge?.requiredConfirmations}`,
		},
		[Statuses.EXPIRED]: {
			icon: <PaymentFailedIcon />,
			description: 'Your payment has been expired.',
			title: 'Expired',
		},
		[Statuses.FAILED]: {
			icon: <PaymentFailedIcon />,
			description: `Your payment has failed. Please contact ${payment?.business.name}.`,
			title: 'Failed',
		},
		[Statuses.CANCELLED]: {
			icon: <PaymentFailedIcon />,
			description: 'Your payment has been cancelled.',
			title: 'Cancelled',
		},
		[Statuses.AWAITING_PAYMENT]: {
			icon: <SendPaymentIcon />,
			description: `To send the payment, please tap on the button below. You will be redirected to our partner, ${ucFirst(
				payment?.onRampCryptoCharge?.currentProvider || ''
			)}, to complete your transaction.`,
			title: 'Send payment',
		},
		[Statuses.UNDERPAID]: {
			icon: (
				<div className={styles.awaitingPaymentIcon}>
					<UnderpaidPaymentIcon />
				</div>
			),
			description:
				'We received your payment, but the amount was less than what was required to confirm this payment. Please contact us so we can assist you.',
			title: 'Underpayment',
		},
	}

	const handleOnRampOptions = () => {
		const currentProviders = onRampOptions?.allPaymentMethods.find(
			paymentMethod => paymentMethod.method === payment?.onRampCryptoCharge?.paymentMethod
		)?.providers

		return (
			currentProviders &&
			currentProviders[
				currentProviders?.findIndex(
					provider => payment?.onRampCryptoCharge?.currentProvider === provider
				) + 1
			]
		)
	}

	if (onRampOptions && handleOnRampOptions() && payment?.status === Statuses.AWAITING_PAYMENT) {
		return (
			<IssuesStep
				handleOnRampOptions={handleOnRampOptions}
				payment={payment}
				getPayment={getPayment}
			/>
		)
	}

	return (
		<div className={styles.paymentStep}>
			{isPaymentIssuesModalActive && (
				<Modal
					closeModal={() =>
						closeModal(setIsPaymentIssuesModalActive, setIsPaymentIssuesModalVisible)
					}
					visible={isPaymentIssuesModalVisible}
					setVisible={setIsPaymentIssuesModalVisible}
				>
					<PaymentIssuesModal
						close={() => closeModal(setIsPaymentIssuesModalActive, setIsPaymentIssuesModalVisible)}
						supportEmail={payment?.business.supportEmail}
						paymentId={payment?.id}
						getPayment={getPayment}
					/>
				</Modal>
			)}

			{(payment?.status === Statuses.CONFIRMING || payment?.status === Statuses.SUCCESS) && (
				<div className={styles.buttonWrapper}>
					<Button style={ButtonStyles.PRIMARY} onClick={openLinkHandler}>
						View payment
					</Button>
				</div>
			)}

			<div className={styles.stepInner}>
				<div className={styles.statusIcon}>{payment && statusData[payment?.status].icon}</div>
				<header className={`${styles.header} ${styles.mbMedium}`}>
					<div>
						<h2>{payment && statusData[payment?.status].title}</h2>
						<p>{payment && statusData[payment?.status].description}</p>
					</div>
				</header>

				{payment?.status === Statuses.AWAITING_PAYMENT ? (
					<button
						className={styles.bigButton}
						onClick={() =>
							window.open(payment?.onRampCryptoCharge?.purchaseUrl, '_blank', 'noopener')
						}
					>
						<OpenLinkSVG />
						Pay
					</button>
				) : (
					<div className={styles.buttons}>
						{payment?.business.supportEmail && (
							<button
								className={styles.bigButton}
								onClick={() =>
									window.open(`mailto:${payment?.business.supportEmail}`, '_blank', 'noopener')
								}
							>
								<ChatSVG />
								Contact support
							</button>
						)}

						{payment?.business.siteUrl && (
							<button
								className={styles.bigButton}
								onClick={() => window.open(payment?.business.siteUrl, '_blank', 'noopener')}
							>
								<OpenLinkSVG />
								Visit our website
							</button>
						)}
					</div>
				)}

				<div className={styles.transactionInfo}>
					<div className={styles.transactionInfoItem}>
						<p>Status</p>
						<span>{payment?.status}</span>
					</div>
					<div className={styles.transactionInfoItem}>
						<p>Transaction ID</p>
						<p>{payment?.id}</p>
					</div>
					<div className={styles.transactionInfoItem}>
						<p>Date & time</p>
						<p>
							{payment?.timeline[0].time &&
								format(new Date(payment?.timeline[0].time), 'MM/dd/yyyy, HH:mm aa')}
						</p>
					</div>
					<div className={styles.transactionInfoItem}>
						<p>Gateway</p>
						{cryptoCharge ? (
							<div className={styles.gateway}>
								<img src={getCryptoIcon(cryptoCharge?.coinName)} alt={cryptoCharge?.coinName} />
								{cryptoCharge?.coinName}
							</div>
						) : (
							<div className={styles.gateway}>
								<img src={getPaymentMethodIcon(onRampCryptoCharge?.paymentMethod)} alt='' />
								{getPaymentMethodName(onRampCryptoCharge?.paymentMethod)}
							</div>
						)}
					</div>
					{payment?.status !== Statuses.AWAITING_PAYMENT && (
						<div className={styles.transactionInfoItem}>
							<p>Exchange rate</p>
							<p>
								1 {getCryptoShortName(cryptoCharge?.coinName)} = {cryptoCharge?.exchangeRate} USD
							</p>
						</div>
					)}
				</div>
				{payment?.status === Statuses.FAILED && (
					<p className={styles.help} onClick={() => setIsPaymentIssuesModalActive(true)}>
						Issues with your payment? Click here for help.
						<QuestionMarkSVG />
					</p>
				)}
			</div>
		</div>
	)
}

export default FinalStep

import React, { FC } from 'react'
import styles from './FormComponents.module.scss'

interface InputProps {
	value: string | number
	setValue: (value: string) => void
	placeholder?: string
	type?: string
	required?: boolean
	pattern?: string
	disabled?: boolean
}

const Input: FC<InputProps> = ({
	value,
	setValue,
	placeholder,
	type,
	pattern,
	required,
	disabled,
}) => {
	return (
		<input
			className={styles.input}
			placeholder={placeholder}
			value={value}
			type={type}
			onChange={event => setValue(event.target.value)}
			pattern={pattern}
			required={required}
			disabled={disabled}
		/>
	)
}

export default Input

import React, { FC, useState } from 'react'
import styles from './Modals.module.scss'
import CloseButton from '../UI/CloseButton/CloseButton'
import Button, { ButtonStyles } from '../UI/Button/Button'
import Input from '../UI/FormComponents/Input'
import { useAPI } from '../../hooks/useAPI'
import { PublicPaymentsHostedPageService } from '../../API/PublicPaymentsHostedPageService'

interface PaymentIssuesModalProps {
	close: () => void
	paymentId?: string
	supportEmail?: string
	getPayment: () => void
}

const PaymentIssuesModal: FC<PaymentIssuesModalProps> = ({
	close,
	supportEmail,
	paymentId,
	getPayment,
}) => {
	const [isLoading, , manuallyLinkTransaction] = useAPI(
		() => PublicPaymentsHostedPageService.manuallyLinkTransaction(paymentId, transactionId),
		false,
		'Successfully submitted',
		{
			onSuccess: () => {
				close()
				getPayment()
			},
		}
	)
	const [transactionId, setTransactionId] = useState('')

	return (
		<div className={styles.modalContentInner}>
			<header className={styles.header}>
				<h1>Payment Issues</h1>
				<CloseButton onClick={close} />
			</header>
			<div className={styles.body}>
				<p>
					If you sent crypto to the requested address, but it wasn’t recognized by the system,
					please enter the transaction ID below.
				</p>
				<Input value={transactionId} setValue={setTransactionId} placeholder={'Transaction ID'} />
				<Button
					style={ButtonStyles.SECONDARY}
					isLoading={isLoading}
					onClick={manuallyLinkTransaction}
				>
					Submit
				</Button>
			</div>
			{supportEmail && (
				<p className={styles.help}>
					Still need help? Contact <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
				</p>
			)}
		</div>
	)
}

export default PaymentIssuesModal

export enum PaymentMethods {
	PAYPAL = 'PAYPAL',
	VENMO = 'VENMO',
	BANK_CARD = 'BANK_CARD',
	CRYPTO = 'CRYPTO',
	WIRE = 'WIRE',
	CASHAPP = 'CASHAPP',
	APPLE_PAY = 'APPLE_PAY',
	GOOGLE_PAY = 'GOOGLE_PAY',
	SEPA = 'SEPA',
	OPEN_BANKING = 'OPEN_BANKING',
	FASTER_PAYMENT_BANK_TRANSFER = 'FASTER_PAYMENT_BANK_TRANSFER',
	MAYA = 'MAYA',
	BPI = 'BPI',
	UNION_BANK = 'UNION_BANK',
	GRAB_PAY = 'GRAB_PAY',
	SHOPEE_PAY = 'SHOPEE_PAY',
	GCASH = 'GCASH',
	PIX = 'PIX',
	ASTROPAY = 'ASTROPAY',
	PSE = 'PSE',
	IMPS = 'IMPS',
}

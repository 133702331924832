import { Cryptocurrencies, Cryptocurrency } from '../types/Cryptocurrencies'
import cronosIcon from '../images/Cronos.svg'
import daiIcon from '../images/Dai.svg'
import moneroIcon from '../images/Monero.svg'
import btcCashIcon from '../images/BitcoinCash.svg'
import ethIcon from '../images/Ethereum.svg'
import ltcIcon from '../images/LitecoinSvg.svg'
import shibaIcon from '../images/ShibaInu.svg'
import polygonIcon from '../images/Polygon.svg'
import usdcIcon from '../images/USDCoin.svg'
import tetherIcon from '../images/Tether.svg'
import bitcoinIcon from '../images/BitcoinSvg.svg'
import apeCoinIcon from '../images/Ape.svg'
import uniswapIcon from '../images/Uniswap.svg'
import solanaIcon from '../images/Sol.svg'
import tronIcon from '../images/tronIcon.svg'
import bnbIcon from '../images/BNB.svg'

export const getCryptoIcon = (crypto: Cryptocurrency | undefined) => {
	switch (crypto) {
		case Cryptocurrencies.BITCOIN:
			return bitcoinIcon
		case Cryptocurrencies.BITCOIN_CASH:
			return btcCashIcon
		case Cryptocurrencies.LITECOIN:
			return ltcIcon
		case Cryptocurrencies.ETH_BUSD:
			return bitcoinIcon
		case Cryptocurrencies.ETH_DAI:
			return daiIcon
		case Cryptocurrencies.ETH_CRONOS:
			return cronosIcon
		case Cryptocurrencies.MONERO:
			return moneroIcon
		case Cryptocurrencies.ETHEREUM:
			return ethIcon
		case Cryptocurrencies.ETH_TETHER:
			return tetherIcon
		case Cryptocurrencies.TRX_TETHER:
			return tetherIcon
		case Cryptocurrencies.ETH_BNB:
			return bnbIcon
		case Cryptocurrencies.ETH_USD_COIN:
			return usdcIcon
		case Cryptocurrencies.TRX_USD_C:
			return usdcIcon
		case Cryptocurrencies.ETH_MATIC:
			return polygonIcon
		case Cryptocurrencies.ETH_UNISWAP:
			return uniswapIcon
		case Cryptocurrencies.ETH_APE_COIN:
			return apeCoinIcon
		case Cryptocurrencies.ETH_SHIBA_INU:
			return shibaIcon
		case Cryptocurrencies.TRON:
			return tronIcon
	}
}

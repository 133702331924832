import React from 'react'

const QuestionMarkSvg2 = () => {
	return (
		<svg width='57' height='57' viewBox='0 0 57 57' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M21.0443 17.5345C21.7244 16.1616 22.7749 15.0063 24.077 14.199C25.3791 13.3916 26.881 12.9644 28.4131 12.9655C30.045 12.9654 31.6401 13.4509 32.9953 14.36C34.3505 15.2692 35.4046 16.561 36.0234 18.071C36.6422 19.5811 36.7978 21.2411 36.4704 22.8398C36.1429 24.4386 35.3473 25.9037 34.1846 27.0489C32.357 28.8509 30.1347 30.7954 29.0601 33.0799M28.4131 44.0345V44.071M28.4131 55C24.9331 55 21.4871 54.3146 18.272 52.9828C15.0568 51.6511 12.1355 49.6991 9.67476 47.2383C7.214 44.7776 5.26203 41.8562 3.93028 38.6411C2.59853 35.426 1.91309 31.98 1.91309 28.5C1.91309 25.02 2.59853 21.574 3.93028 18.3589C5.26203 15.1438 7.214 12.2224 9.67476 9.76167C12.1355 7.30092 15.0568 5.34894 18.272 4.01719C21.4871 2.68544 24.9331 2 28.4131 2C35.4413 2 42.1817 4.79196 47.1514 9.76167C52.1211 14.7314 54.9131 21.4718 54.9131 28.5C54.9131 35.5282 52.1211 42.2686 47.1514 47.2383C42.1817 52.208 35.4413 55 28.4131 55Z'
				stroke='#F7931A'
				strokeWidth='3'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default QuestionMarkSvg2

export enum CryptoShortNames {
	BTC = 'BTC',
	ETH = 'ETH',
	LTC = 'LTC',
	BCH = 'BCH',
	USDC = 'USDC',
	USDT = 'USDT',
	XMR = 'XMR',
	BNB = 'BNB',
	BUSD = 'BUSD',
	MATIC = 'MATIC',
	SHIBA = 'SHIBA',
	APE = 'APE',
	CRO = 'CRO',
	DAI = 'DAI',
	UNI = 'UNI',
	TRX = 'TRX',
}

export type CryptoShortName =
	| CryptoShortNames.BTC
	| CryptoShortNames.LTC
	| CryptoShortNames.BCH
	| CryptoShortNames.BNB
	| CryptoShortNames.TRX
	| CryptoShortNames.APE
	| CryptoShortNames.UNI
	| CryptoShortNames.XMR
	| CryptoShortNames.ETH
	| CryptoShortNames.DAI
	| CryptoShortNames.USDT
	| CryptoShortNames.USDC
	| CryptoShortNames.CRO
	| CryptoShortNames.BUSD
	| CryptoShortNames.MATIC
